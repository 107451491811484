import './App.css';
import {ProjectsList,SocialsList} from './components/workList.js'
import Header from './components/portfolio.js'

// import Tagline from './components/taglines'
// import Contact from './components/contact';

import { FaGithub, FaCloudflare  } from 'react-icons/fa'

function App() {
  return (
    <div className="App">
      <Header />
      <div className='textcontainer'>
        <div>
          <p style={{ color: 'white' }}>{'Howdy there, I’m Pijin! Im a digital furry artist who thrives on bringing the anthropomorphic characters to life through pixels and brushes. I have been drawing digitally since 2010 with the release of My Little Pony: Friendship is Magic, particularlly on Tumblr. I worked on several ask blogs including We Three Fillies and Ask Gingersnap at the time until 2014 where I expanded to furry art in general as well as NSFW content. These days I focus on fan art of original characters I like and come across on social media (with some art of my own OCs on the side.) I have now gotten into hacking and development. While I don\'t take commissions anymore, please check out my work and give me a follow! You can find my contacts and socials below.'}</p>
        </div>
      </div>
      <div className='embedcontainer'>
        <div>
        </div>
        <div>
          <h1 style={{ color: 'white' }}>Projects</h1>
          <ProjectsList />
          <div style={{ display: 'flex', 'justify-content': 'center' }}>
          </div>
          <h1 style={{ color: 'white' }}>Contact Information</h1>
          <div style={{ display: 'flex', 'justify-content': 'center' }}>
            <SocialsList />
          </div>
        </div>
      </div>
      <div className='footercontainer'>
        <div>
          <a target={'_blank'} href={'https://github.com/pijinworks/site'} style={{ color: 'white' }} rel="noreferrer"><h1 style={{ color: 'white' }}>{<FaGithub />}{'  This website is OSS!'}</h1></a>
          <a target={'_blank'} href={'https://pages.cloudflare.com/'} style={{ color: 'white' }} rel="noreferrer"><h2 style={{ color: 'white' }}>{<FaCloudflare />}{'  And hosted using Cloudflare Pages'}</h2></a>
        </div>
      </div>
    </div>
  );
}

export default App;
