import '../css/workEntry.css'
import { FaDiscord, FaGithub, FaInstagram, FaReddit, FaTwitch, FaTwitter, FaYoutube } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { BsFillBrushFill } from 'react-icons/bs'
import { SiMisskey, SiFuraffinity  } from 'react-icons/si'

function SocialEntry({entry}) {
  
    const iconMapping = {
        twitter: <FaTwitter />,
        discord: <FaDiscord />,
        github: <FaGithub />,
        instagram: <FaInstagram />,
        reddit: <FaReddit />,
        youtube: <FaYoutube />,
        inkbunny: <BsFillBrushFill />,
        Furaffinity: <SiFuraffinity />,
        twitch: <FaTwitch />,
        misskey: <SiMisskey />,
        email: <HiOutlineMail />
    }
    
  return (
    <div
      id="embedHolder"
      
    >
      <a
        href={entry.link}
        target={'_blank'} rel="noreferrer"
      >
        <h2>
          {((iconMapping[entry.site]) ? iconMapping[entry.site] : '')}{'  '}
          {entry.site}{' • '}{entry.name_prefix}{'pijin'}{entry.name_suffix}
        </h2>
      </a>
    </div>
  )
}

export default SocialEntry
